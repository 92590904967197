// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-code-notes-src-pages-404-tsx": () => import("./../../../node_modules/gatsby-theme-code-notes/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-code-notes-src-pages-404-tsx" */),
  "component---node-modules-gatsby-theme-code-notes-src-templates-note-js": () => import("./../../../node_modules/gatsby-theme-code-notes/src/templates/Note.js" /* webpackChunkName: "component---node-modules-gatsby-theme-code-notes-src-templates-note-js" */),
  "component---node-modules-gatsby-theme-code-notes-src-templates-notes-js": () => import("./../../../node_modules/gatsby-theme-code-notes/src/templates/Notes.js" /* webpackChunkName: "component---node-modules-gatsby-theme-code-notes-src-templates-notes-js" */),
  "component---node-modules-gatsby-theme-code-notes-src-templates-tag-page-js": () => import("./../../../node_modules/gatsby-theme-code-notes/src/templates/TagPage.js" /* webpackChunkName: "component---node-modules-gatsby-theme-code-notes-src-templates-tag-page-js" */)
}

