module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"backgroundColor":"none","maxWidth":900,"linkImagesToOriginal":false,"disableBgImage":true,"wrapperStyle":"margin: 1.5rem 0;"}}],"remarkPlugins":[null,null,null,[null,{"style":"smart"}],null]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-code-notes/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/notes","showDescriptionInSidebar":true,"showThemeInfo":false,"logo":"/images/apiscp-logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ApisCP Notes","short_name":"ApisCheats","description":"Notes and cheats for a better ApisCP administration experience.","start_url":"/","background_color":"#e9fcf9","theme_color":"#169a84","display":"standalone","icon":"static/images/apiscp-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"54863d47044bd3c4502ac86b6b9e2eac"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*","/tag/*"]},
    }]
